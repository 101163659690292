html, body{
    font-family: sans-serif;
    background: url("../images/poon.jpg") no-repeat;
    background-size: cover;
    height: 100%;
}

h2{
    font-family: sans-serif;
    font-size: 15px;
}
.desc{
    font-size: 11px;
}

a {
    text-decoration: none;
    color: #f2f2f2;
}

